import {
    EnvironmentType,
    IEnvironmentSettings,
} from '../models/configuration/environment';

import { LogLevel } from '../models/configuration/log';
import { createEnvironmentSettings } from './environment.factory';

export const environment: IEnvironmentSettings = createEnvironmentSettings({
    production: false,
    logLevel: LogLevel.Information,
    environment: EnvironmentType.QA,
    authority: 'https://auth-qa.telxl.net',
    apiBaseUrl: 'https://api-qa.telxl.net',
    heartbeatInterval: 30,
    pollingInterval: 60,
    cacheLifeTime: 60,
    isLoggingEnabled:false,
    scope: 'gum bp.access openid profile email offline_access',
});
